import React, { useState, useEffect } from 'react'
import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, collection, query, where, orderBy } from "firebase/firestore/lite";
import { Layout } from '../components/layout'
import { SEO } from '../components/seo'

const WorksPage = ({ location }) => {
	const [showTitle, setShowTitle] = useState(false)

	const firebaseConfig = {
		apiKey: "AIzaSyBm6dmlOKzt7a2OmqVPB0Jd-9FKp2-EISg",
		authDomain: "icm-portal.firebaseapp.com",
		projectId: "icm-portal",
	};
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [works, setWorks] = useState([]);
	const [filter, setFilter] = useState('')

	useEffect(() => {
		const fetchWorksData = async () => {
			const params = new URLSearchParams(location.search);
			const category = params.get("category")
			const role = params.get("role")

			const queryConstraints = [orderBy("number", "desc")]

			if (category) {
				setFilter(category)
				queryConstraints.push(where("categories", "array-contains", category))
			}
			if (role) {
				setFilter(role)
				queryConstraints.push(where("roles", "array-contains", role))
			}

			const querySnapshot = await getDocs(query(collection(db, "works"), ...queryConstraints));
	
			setWorks([]);
			querySnapshot.forEach((doc) => {
				setWorks((prevState) => [
					...prevState,
					{
						id: doc.id,
						data: {
							number: doc.data().number,
							client: doc.data().client,
							categories: doc.data().categories,
							roles: doc.data().roles,
							movie: doc.data().movie,
						},
					},
				]);
			});
		};

		setShowTitle(true)
		fetchWorksData()
	}, [db, location.search])
	
	return (
		<Layout>
			<SEO
				title='WORKS'
				description=''
				keywords={['inspiration cult', 'icm', 'インスピレーションカルト', 'フォトグラファー', 'クリエイター', 'クルー']}
			/>
			<div className="works">
				<div className="title">
					<div className={`animation1 ${showTitle ? 'show' : ''}`}>
						<div>WORKS</div>
					</div>
				</div>
				<div className="contact-me">
					詳細はお問い合わせください。
				</div>
				<div className="contact-link">
					<a href="/contact">
						CONTACT
					</a>
				</div>
				{filter && <div className="tag">
					<span>{filter}</span>
				</div>}
				<div className="works-list">
					{works.map(work => (
						<div key={work.id} className="work-card">
							<div className="work-video" style={{
								backgroundImage: `url(https://storage.googleapis.com/static.inspirationcult.net/${work.data.movie})`
							}} />
							<div className="work-info">
								<div className="work-title">{work.data.client}</div>
								<div className="work-categories">
									{work.data.categories.map((category, index) => (
										<a key={index} href={`/works?category=${category}`}>
											<div>{category}</div>
										</a>
									))}
								</div>
								<div className="work-roles">
									{work.data.roles.map((role, index) => (
										<a key={index} href={`/works?role=${role}`}>
											<div>{role}</div>
										</a>
									))}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</Layout>
	)
}

export default WorksPage
